.slidecounter {
	.inner {
		display: flex;
		align-items: center;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;

		li {
			width: 12px;
			height: 12px;
			border: solid 1px #000;
			border-radius: 12px;
			margin-right: 10px;
			position: relative;
			overflow: hidden; 
			cursor: pointer;

			@include breakpoint(small) {
				width: 10px;
				height: 10px;
				margin-right: 20px;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: #000;
				transform: scale(0);
				border-radius: 20px;
			}

			&.active, &.hover {
				&:before {
					transform: scale(1.1);
				}
			}
		}
	}
}