.archivefilter {
	display: flex;
	width: 100%;
	margin-bottom: 10px;
	font-size: 18px;

	@include breakpoint(mobile) {
		font-size: 16px;
	}

	.inner {
		display: flex;

		.filter-name {
			margin-right: 100px;

			@include breakpoint(small) {
				display: none;
			}
		}

		ul {
			top: 0;
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				display: inline-block;
				margin-right: 20px;

				a {
					color: #ccc;

					&:hover {
						color: #000;
					}
				}

				&.selected {
					a {
						color: #000;
					}
				}
			}
		}		
	}
}