a {
	text-decoration: none;
	color: #000;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  line-height: 1;
}

h1 {
	font-size: 52px;
}

p {
	font-size: 16px;
	line-height: 1.4;
	letter-spacing: 0.3px;
	margin-bottom: 30px;
	margin-top: 0;
	width: 100%;

	strong {
		font-weight: normal;
	}

	@include breakpoint(mobile) {
		margin-bottom: 25px;
	}

	@include breakpoint(mobileXSmall) {
		font-size: 14px;
	}

	a {
		text-decoration: underline;
	}
}

ul {
	font-size: 16px;
	padding-left: 16px;
}