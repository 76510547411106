@mixin breakpoint($point) {
    @if $point == desktopLarge {
        @media screen and (min-width: 1600px) {
            @content;
        }
    }

    @if $point == desktopMedium {
        @media screen and (min-width: 1401px) {
            @content;
        }
    }

    @if $point == desktop {
        @media screen and (min-width: 1201px) {
            @content;
        }
    }

    @if $point == xlarge {
        @media screen and (max-width: 1200px) {
            @content;
        }
    }

    @if $point == large {
        @media screen and (max-width: 1050px) {
            @content;
        }
    }

    @if $point == medium {
        @media screen and (max-width: 900px) {
            @content;
        }
    }

    @if $point == small {
        @media screen and (max-width: 800px) {
            @content;
        }
    }

    @if $point == mobileXLarge {
        @media screen and (max-width: 700px) {
            @content;
        }
    }

    @if $point == mobile {
        @media screen and (max-width: 600px) {
            @content;
        }
    }

    @if $point == mobileSmall {
        @media screen and (max-width: 420px) {
            @content;
        }
    }

    @if $point == mobileXSmall {
        @media screen and (max-width: 350px) {
            @content;
        }
    }

    @if $point == shortScreenL {
        @media screen and (max-height: 735px) {
            @content;
        }
    }

    @if $point == shortScreenM {
        @media screen and (max-height: 650px) {
            @content;
        }
    }
}