// col width
// - normal 40px
// - mobile 16px

$marginTotal: 40px;
$gridColMargin: 10px;
$gridColMarginWide: 20px;
$gridColWidth: 6.25%; // 16 col
$pageGutter: 30px;

$marginTotalMobile: 16px;
$gridColMarginMobile: 4px;
$gridColMarginWideMobile: 8px;
$pageGutterMobile: 12px;


.PageModule {
	padding-right: $pageGutter;
	padding-left: $pageGutter;

	@include breakpoint(small) {
		padding-right: $pageGutterMobile;
		padding-left: $pageGutterMobile;
	}

	&.wide {
		padding-left: $gridColMarginWide;
		padding-right: $gridColMarginWide;

		.col {
			padding-left: $gridColMarginWide;
			padding-right: $gridColMarginWide;
		}
	}

	.col {
		padding-left: $gridColMargin;
		padding-right: $gridColMargin;

		@include breakpoint(small) {
			padding-left: $gridColMarginMobile;
			padding-right: $gridColMarginMobile;
		}
	}
}

.col {
	display: flex;
	position: relative;

	.inner {
		width: 100%;
	}

	&.indicator {
		margin-bottom: 20px;
		
		.inner {
			border-bottom: 1px solid #000;
    		padding-bottom: 4px;
		}
	}
}

@for $i from 1 through 16 {
	&.col#{$i} {
		width: $gridColWidth * $i;
	}
}

@for $i from 1 through 16 {
	&.col#{$i}-xl {
		@include breakpoint(xlarge) {
			width: $gridColWidth * $i;
		}
	}
}

@for $i from 1 through 16 {
	&.col#{$i}-l {
		@include breakpoint(large) {
			width: $gridColWidth * $i;
		}
	}
}

.col33-l {
	@include breakpoint(large) {
		width: 33.3333%;
	}
}

@for $i from 1 through 16 {
	&.col#{$i}-m {
		@include breakpoint(medium) {
			width: $gridColWidth * $i;
		}
	}
}

@for $i from 1 through 16 {
	&.col#{$i}-s {
		@include breakpoint(small) {
			width: $gridColWidth * $i;
		}
	}
}

@for $i from 1 through 16 {
	&.col#{$i}-mobxl {
		@include breakpoint(mobileXLarge) {
			width: $gridColWidth * $i;
		}
	}
}