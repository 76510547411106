* {
	box-sizing: border-box;
}

body {
  position: relative;
  font-family: $fontMain;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;

  &.modal-open {
  	overflow-y: hidden;
  	margin-right: 15px;

    &.mobile {
      margin-right: 0;
    }
  }
}