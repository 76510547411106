.btn {
	padding: 21px 40px;
	display: inline-block;
	border-radius: 100px;
	line-height: 1.3;
	cursor: pointer;
	text-align: center;

	&.cta {
		font-size: 40px;
	}

	&.black {
		background: #000;
		color: #fff;
	}

	&.blue {
		background: #1801FF;
		color: #fff;
	}

	&.white {
		background: #fff;
		color: #000;
	}

	&.border {
		border: 1px #000 solid;
	}

	&:hover {
		border-radius: 0 !important;
	}

	&.large {
		font-size: 60px;
		border-radius: 120px;
		padding-left: 70px;
		padding-right: 70px;
		padding-top: 40px;
		padding-bottom: 40px;
	}

	&.small {
		font-size: 24px;
		border-radius: 120px;
		padding-left: 50px;
		padding-right: 50px;
	}
}

.btn-viewall {
	display: inline-block;
/*
	span {
		display: inline-block;
		transition: ease .3s;
		transform: translateX(0);
	}

	&:after, &:before {
		content: '';
		position: absolute;
		right: 15px;
		top: 7px;
		height: 13px;
		width: 1px;
		background: #000;
		opacity: 0;
		transition: all ease .3s;
		transform: translateX(-4px);
		transform-origin: center center;
		transform: translateX(-4px) rotateZ(90deg);
	}

	&:hover {
		span {
			transform: translateX(-20px);
		}

		&:before {
			opacity: 1;
			transform: translateX(0) rotateZ(90deg);
		}

		&:after {
			opacity: 1;
			transform: translateX(0) rotateZ(180deg);
		}
	}*/
}