.PageModule {
	display: flex;
	flex-wrap: wrap;
	margin-top: 100px;
	margin-bottom: 100px;

	&:first-of-type {
		margin-top: 240px;

		&.WeKnowModule {
			//margin-top: 28.5vw;
			margin-bottom: 0;
			padding-bottom: 40px;

			@include breakpoint(desktopLarge) {
				margin-top: 340px;	
			}

			@include breakpoint(large) {
				margin-top: 240px;
			}

			@include breakpoint(shortScreenM) {
				margin-top: 180px;
			}

			@include breakpoint(mobile) {
				margin-top: 200px;
			}
		}

		&.HeaderTextModule {
			margin-bottom: calc(-100vh);
		}


		@include breakpoint(small) {
			margin-top: 150px;
		}
	}

	@include breakpoint(xlarge) {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	@include breakpoint(small) {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}