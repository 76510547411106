/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 2, 2020 */



@font-face {
    font-family: 'psv_winbatsirregular';
    src: url('psvwinbats-irregular-webfont.woff2') format('woff2'),
         url('psvwinbats-irregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}