@import './../../../scss/variables/index.scss';

.screensaver {
	position: fixed;
	z-index: 1000;
	font-size: 500px;
	font-family: 'psv_winbatsirregular';
	color: #000;

	.test {
		font-size: 40px;
	}

	.single {
		position: absolute;
		top: 50vh;
		left: 50vw;
		transform: translate(-50%, -50%);
		// opacity: 0;
		pointer-events: none;

		.inner {
			transition: all linear 20s;

			span {
				display: block;
				animation-name: screenSaver;
				animation-duration: 10s;
				animation-iteration-count: infinite; 
				animation-timing-function: linear;
			}

			&.backwards {
				span {
					animation-direction: reverse;
				}
			}
		}

		&.loaded {
			opacity: 1;
		}
	}
}

@keyframes screenSaver {
	0% {transform: rotateZ(0deg)}
	100% {transform: rotateZ(360deg)}
}