.hs_form {
	&#pitchform_target_div, &#hs_form {
		width: 100%;

		form {
		}

		.hs-fieldtype-text {
			width: 100%;

			input {
				padding: 24px;
				width: 100%;
				font-size: 21px;
				border: solid 1px #000;
				margin-top: 10px;
				-webkit-appearance: none;
				-moz-appearance: none;
				border-radius: 0;
			}
		}

		.hs-fieldtype-checkbox, .hs-dependent-field {
			ul {
				list-style: none;
				padding: 0;

				li {
					position: relative;
					margin-bottom: 10px;
					display: inline-block;
					padding-right: 20px;
					width: 50%;
					margin-bottom: 40px;

					@include breakpoint(mobile) {
						width: 100%;
					}
				}

				input[type="checkbox"] {
					position: absolute;
					height: 0;
					width: 0;
					outline: none;
					cursor: pointer;
					border: none;
					// opacity: 0;

					&:after {
						content: '';
						position: absolute;
						width: 48px;
						height: 48px;
						background: #fff;
						border: solid 1px #000;
						top: -16px;
						left: 0;
					}

					& + span {
						padding-left: 70px;
						cursor: pointer;
						margin-left: 0;

						&:before {
							content: '';
							opacity: 0;
							position: absolute;
							top: 0;
							left: 0;
							height: 1px;
							width: 34px;
							background: #000;
							transform: translateX(12px) translateY(12px) rotateZ(45deg);
						}

						&:after {
							content: '';
							opacity: 0;
							position: absolute;
							top: 0;
							left: 0;
							height: 1px;
							width: 34px;
							background: #000;
							transform: translateX(12px) translateY(12px) rotateZ(-45deg);
						}
					}

					&:checked + span {
						&:before {
							opacity: 1;
						}

						&:after {
							opacity: 1;
						}

					}

					// &:focus + span {
					// 	&:before {
					// 		border: solid 1px #0d00ff;
					// 	}

					// 	&:checked {
					// 		&:before {
					// 			border: solid 1px #0d00ff;
					// 		}
					// 	}
					// }
				}
			}
		}

		.hs-dependent-field {
			ul {
				li {
					margin-bottom: 0;
				}

				// input[type="checkbox"] {
				// 	& + span {
				// 		&:before {
				// 			top: 18px;
				// 		}

				// 		&:after {
				// 			top: 32px;
				// 		}
				// 	}
				// }
			}
		}

		.hs-fieldtype-textarea {
			textarea {
				font-size: 21px;
				font-family: 'klarheit_groteskregular';
				padding: 24px;
				width: 100%;
				border: solid 1px #000;
				height: 300px;
				margin-top: 10px;
				border-radius: 0;
			}
		}

		.hs-fieldtype-file {
			position: relative;
			margin-bottom: 60px !important;

			input {
				margin-top: 10px;
				font-size: 21px;
				padding-left: 30px;

				&::file-selector-button {
					background: #000;
					transform: translateX(-30px);
					padding: 20px 60px;
					border: none;
					color: #fff;
					font-size: 21px;
					cursor: pointer;

					&:hover {
						border-radius: 0;
					}

					&:focus {
						outline: none;
					}
				}

				&::-webkit-file-upload-button {
					border-radius: 100px;
					transform: translateX(-30px);
					background: #000;
					padding: 20px 60px;
					border: none;
					color: #fff;
					font-size: 21px;
					cursor: pointer;

					&:hover {
						border-radius: 0;
					}

					&:focus {
						outline: none;
					}
				}
			}

			label {
				&:after {
					display: none;
					content: 'select file';
					color: #fff;
					font-size: 21px;
					padding: 20px 0;
					position: absolute;
					background: #000;
					left: 0;
					bottom: -120px;
					cursor: pointer;
					width: 100%;
					text-align: center;
				}
			}
		}

		.hs-fieldtype-select {
			select {
				width: 100%;
				padding: 20px;
				font-size: 21px;
				font-family: 'klarheit_groteskregular';
				border: solid 1px #000;
				background: #fff;
				margin-top: 10px;
				appearance: none;
				position: relative;
				-webkit-appearance: none;
				-moz-appearance: none;
				border-radius: 0;

				&:after {
					content: '';
					position: absolute;
					width: 20px;
					height: 10px;
					background: #000;
					top: 0;
					right: 0;
				}
			}
		}

		.hs-submit {
			input {
				border: none;
				font-size: 56px;
				padding: 20px 20px;
				width: 100%;
				background: #000;
				color: #fff;
				border-radius: 100px;
				cursor: pointer;
				margin-top: 40px;
				-webkit-appearance: none;
				-moz-appearance: none;

				&:hover {
					border-radius: 0;
				}

				@include breakpoint(large) {
					font-size: 46px;
				}

				@include breakpoint(small) {
					font-size: 30px;
				}
			}
		}

		.legal-consent-container {
			.hs-richtext p {
				font-size: 16px;
				margin-bottom: 20px;
			}

			.hs-dependent-field ul {
				margin-top: 30px;

				li {
					width: 100%;
				}
			}
		}

		.hs-fieldtype-textarea, .hs-fieldtype-checkbox, .hs-fieldtype-text, .hs-fieldtype-select, .hs-fieldtype-file, .hs-dependent-field {
			margin-bottom: 30px;

			.input {
				margin-right: 0;
			}

			textarea, select, input {
				-webkit-appearance: none;
				-moz-appearance: none;

				&:focus {
					outline: 1px solid #000;
				}

				&.error {
					border-color: red;

					&:focus {
						border-color: #000;
					}
				}
			}

			.hs-field-desc {
				font-size: 16px;
				margin-bottom: 10px;
				margin-top: 10px;
			}

			label {
				span {
					font-size: 18px;
				}
			}

			.hs-error-msgs {
				margin-bottom: 0;
				margin-top: 10px;
				padding: 0;
				list-style: none;
				font-size: 16px;
				color: red;
			}
		}

		.submitted-message {
			p {
				font-size: 30px;
				text-align: center;
				margin-bottom: 0;
				padding: 0 40px;

				@include breakpoint(mobile) {
					font-size: 24px;
				}
			}
		}
	}

   input {
   	font-family: $fontMain;
   }

	&#hs_newsletter_target {
		margin-top: 10px;
		margin-bottom: 10px;

		form {
			display: flex;
			width: 100%;
			flex-wrap: wrap;
		}

		input {
			width: 100%;
		}

		.hs_email, .hs_firstname {
			width: calc(50% - 220px / 2);

			input {
				padding: 24px;
				border: solid 1px #000;
				font-size: 21px;
				-webkit-appearance: none;
				-moz-appearance: none;
				border-radius: 0;

				&:focus {
					outline: solid 1px #000;
				}
			}

			label {
				// display: none;
			}

			span {
				&.hs-form-required {
					display: none;
				}
			}
		}

		.hs-form-field {
			&:nth-child(1) {
				padding-right: 10px;

				@include breakpoint(small) {
					padding-right: 0;
				}
			}

			&:nth-child(2) {
				padding-left: 10px;

				@include breakpoint(small) {
					padding-left: 0;
					margin-top: 10px;
				}
			}

		}

		.hs_submit {
			width: 200px;

			input[type="submit"] {
				background: #000;
				font-size: 21px;
				padding: 24px 30px;
				color: #fff;
				cursor: pointer;
				border: solid 1px #000;
				border-radius: 100px;
				-webkit-appearance: none;
				-moz-appearance: none;
				margin-left: 20px;

				&:hover {
					border-radius: 0;
				}

				@include breakpoint(small) {
					margin-left: 0;
					margin-top: 10px;
				}
			}
		}

		.hs_email, .hs_submit, .hs_firstname {
			@include breakpoint(small) {
				width: 100%;
			}
		}

		.hs-error-msgs {
			color: #ff0000;
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			font-size: 16px;
		}

		.hs_error_rollup {
			width: 100%;
			order: 1;

		}

		.submitted-message {
			p {
				margin: 20px 0;
				font-size: 40px;
				text-align: center;

				@include breakpoint(small) {
					font-size: 30px;
				}
			}
		}
	}
}